.about {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
  }
  
  .about h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .about p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
  }
.contact {
    padding: 4rem 2rem;
    background-color: #ffffff;
  }
  
  .contact h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    text-align: center;
  }
  
  .contact form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact input,
  .contact textarea {
    margin-bottom: 1rem;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .contact textarea {
    min-height: 150px;
  }
  
  .contact button {
    padding: 0.8rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact button:hover {
    background-color: #0056b3;
  }

  .contact p {
    font-size: 1.1rem;
    color: #4CAF50;
    text-align: center;
  }
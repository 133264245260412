.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../public/images/plitvice.jpg');  /* Adjust this path if necessary */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;  /* This creates the parallax effect */
  opacity: 0.7;  /* Adjust for desired image intensity */
  z-index: -1;
}

.hero-content {
  max-width: 800px;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);  /* Semi-transparent white background */
  padding: 2rem;
  border-radius: 10px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333;
}

.hero h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #666;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #666;
}

.cta-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}
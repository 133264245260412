.blog {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
  }
  
  .blog h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    text-align: center;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .blog-post {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-post:hover {
    transform: translateY(-5px);
  }